import { useEffect, useState } from "react";
import api from "../services/api";

type TipoDocumento = {
  label: string;
  id: string;
};

type Pais = {
  id: string;
  nombre: string;
};

type Departamento = {
  id: string;
  nombre: string;
  pais_id: number;
};

type Ciudad = {
  id: string;
  nombre: string;
  departamento_id: string;
};

const useFormOptions = () => {
  const [paises, setPaises] = useState<Pais[]>([]);
  const [departamentos, setDepartamentos] = useState<Departamento[]>([]);
  const [ciudades, setCiudades] = useState<Ciudad[]>([]);
  const [tiposDocumentos, setTiposDocumentos] = useState<TipoDocumento[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    api
      .get("/register-options")
      .then(({ data }) => {
        setPaises(data.paises);
        setDepartamentos(data.departamentos);
        setCiudades(data.ciudades);
        setTiposDocumentos(data.tiposDocumentos);
      })
      .catch(console.log)
      .finally(() => {
        setIsLoading(false);
      });
  }, []);
  return { paises, departamentos, ciudades, tiposDocumentos, isLoading };
};

export default useFormOptions;

import React from "react";

type Props = {
  icon: string;
  title: string;
  description: string;
};

const Service = ({ icon, title, description }: Props) => {
  return (
    <div className="w-full flex flex-col justify-center items-center ">
      <div className="w-full h-[200px] lg:h-[150px] mb-2 flex justify-center">
        <img src={icon} alt="" className=" object-contain" />
      </div>
      <span className="font-bold mb-1">{title}</span>
      <div className="w-full px-16">
        <p className="text-center">{description}</p>
      </div>
    </div>
  );
};

export default Service;

import React from "react";

type Props = {
  label?: string;
  placeholder?: string;
  error?: string;
  onChange: (value: string) => void;
  defaultValue?: string;
};

function TextInput({
  label = "",
  onChange,
  placeholder = "",
  error = "",
  defaultValue = "",
}: Props) {
  const id = Math.random() + "textarea";
  return (
    <div className="w-full flex flex-col">
      {!!label && (
        <label htmlFor={id} className="text-lg lg:text-xl ml-2 mb-2">
          {label}
        </label>
      )}

      <textarea
        id={id}
        placeholder={placeholder}
        className={`w-full h-min  px-4 py-2 lg:p-4 border rounded-full text-xs lg:text-base  ${
          !!error
            ? "border-red-500 placeholder:text-red-500 focus:outline-none"
            : "border-secondary"
        }`}
        onBlur={(e) => onChange(e.target.value)}
        defaultValue={defaultValue}
      ></textarea>
      {!!error && (
        <span className="mt-2 text-red-500 text-xs lg:text-sm">{error}</span>
      )}
    </div>
  );
}

export default TextInput;

import { useState } from "react";
import api from "../services/api";
import axios from "axios";
import { Cliente } from "../providers/AuthProvider";

type LoginParams = {
  email: string;
  password: string;
  "g-recaptcha-response": string | null;
};

type LoginErrors = {
  email: string[];
  password: string[];
  "g-recaptcha-response": string[];
  server: string[];
};

const DEFAULT_ERRORS: LoginErrors = {
  email: [""],
  password: [""],
  "g-recaptcha-response": [""],
  server: [""],
};

const useLogin = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<LoginErrors>(DEFAULT_ERRORS);

  const serverError = () => {
    setErrors({
      ...DEFAULT_ERRORS,
      server: ["No se pudo enviar el mensaje"],
    });
  };
  const login = ({
    params,
    onSuccess,
  }: {
    params: LoginParams;
    onSuccess: (cliente: Cliente) => void;
  }) => {
    setIsLoading(true);
    return api
      .post("/login", params)
      .then((response) => {
        setErrors(DEFAULT_ERRORS);
        onSuccess(response.data.cliente);
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 422) {
            setErrors({ ...DEFAULT_ERRORS, ...error.response?.data });
          } else if (error.response?.status === 401) {
            setErrors({
              ...DEFAULT_ERRORS,
              email: ["Email y/o contraseña son incorrectos"],
            });
          } else {
            serverError();
          }
        } else {
          serverError();
        }
      })
      .finally(() => setIsLoading(false));
  };

  return { login, isLoading, errors };
};
export default useLogin;

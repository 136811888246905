import React from "react";
import bossLogo from "../assets/img/brands/hugo-boss.webp";
import victoriaSecretsLogo from "../assets/img/brands/victoria-secrets.webp";
import tommyHilfigerLogo from "../assets/img/brands/tommy-hilfigger.jpg";
import gapLogo from "../assets/img/brands/gap.webp";
import guessLogo from "../assets/img/brands/guess.webp";
import nauticaLogo from "../assets/img/brands/nautica.webp";
import cartersLogo from "../assets/img/brands/carters.webp";
import gymboreeLogo from "../assets/img/brands/gymboree.webp";
import nikeLogo from "../assets/img/brands/nike.webp";
import oldNavyLogo from "../assets/img/brands/old-navy.webp";
import skipHopLogo from "../assets/img/brands/skip-hop.webp";
import oshKoshLogo from "../assets/img/brands/oshkosh.webp";
import babolatLogo from "../assets/img/brands/babolat.webp";
import zaraLogo from "../assets/img/brands/zara.webp";
import sheinLogo from "../assets/img/brands/shein.webp";
import decathlonLogo from "../assets/img/brands/decathlon.webp";
import headLogo from "../assets/img/brands/head.webp";
import ikeaLogo from "../assets/img/brands/ikea.webp";
import elCorteInglesLogo from "../assets/img/brands/el-corte-ingles.webp";
import mangoLogo from "../assets/img/brands/mango.webp";
import bullPadelLogo from "../assets/img/brands/bullpadel.webp";
import noxLogo from "../assets/img/brands/nox.webp";
import leftiesLogo from "../assets/img/brands/lefties.webp";
import hmLogo from "../assets/img/brands/hm.webp";

function BrandsSection() {
  const groups = [
    {
      country: "Estados Unidos",
      brands: [
        {
          logo: bossLogo,
          link: "https://www.hugoboss.com",
        },
        {
          logo: bossLogo,
          link: "https://www.hugoboss.com",
        },
        {
          logo: victoriaSecretsLogo,
          link: "https://www.victoriassecretbeauty.com.py/",
        },
        {
          logo: tommyHilfigerLogo,
          link: "https://es.tommy.com/",
        },
        {
          logo: gapLogo,
          link: "https://www.gap.com/",
        },
        {
          logo: guessLogo,
          link: "https://www.guess.com/us/en/home/",
        },
        {
          logo: nauticaLogo,
          link: "https://www.nautica.com/",
        },
        {
          logo: cartersLogo,
          link: "https://www.carters.com/",
        },
        {
          logo: gymboreeLogo,
          link: "https://www.gymboree.com",
        },
        {
          logo: nikeLogo,
          link: "https://www.nike.com",
        },
        {
          logo: oldNavyLogo,
          link: "https://oldnavy.gap.com/",
        },
        {
          logo: skipHopLogo,
          link: "https://www.skiphop.com/",
        },
        {
          logo: oshKoshLogo,
          link: "https://www.oshkosh.com/",
        },
      ],
    },
    {
      country: "Europa",
      brands: [
        {
          logo: zaraLogo,
          link: "https://www.zara.com/py/",
        },
        {
          logo: zaraLogo,
          link: "https://www.zara.com/py/",
        },
        {
          logo: sheinLogo,
          link: "https://es.shein.com/",
        },
        {
          logo: decathlonLogo,
          link: "https://www.decathlon.es/es/",
        },
        {
          logo: headLogo,
          link: "https://www.head.com/es_ES/",
        },
        {
          logo: ikeaLogo,
          link: "https://www.ikea.com/es/es/",
        },
        {
          logo: elCorteInglesLogo,
          link: "https://www.elcorteingles.es/",
        },
        {
          logo: mangoLogo,
          link: "https://shop.mango.com/py/mujer",
        },
        {
          logo: bullPadelLogo,
          link: "https://www.bullpadel.com/es/",
        },
        {
          logo: noxLogo,
          link: "https://noxsport.es/",
        },
        {
          logo: leftiesLogo,
          link: "https://www.lefties.com/",
        },
        {
          logo: hmLogo,
          link: "https://www2.hm.com/en_us/index.html",
        },
        {
          logo: babolatLogo,
          link: "https://www.babolat.com/es",
        },
      ],
    },
  ];
  return (
    <div className="w-full flex flex-col justify-center py-10">
      <h2 className="text-4xl lg:text-5xl text-center font-bold text-primary tracking-tighter mb-8">
        Compra de las mejores marcas
      </h2>
      <div className="w-full flex justify-center">
        <div className="w-full max-w-7xl flex flex-col">
          {groups.map((group, i) => {
            return (
              <div
                key={i + Math.random()}
                className="w-full flex flex-col justify-center items-center my-10"
              >
                <span className="font-bold text-primary text-3xl mb-8">
                  {group.country}
                </span>
                <div className="w-full hidden lg:grid grid-cols-6 gap-16">
                  {group.brands.slice(1, 13).map((brand, j) => {
                    return (
                      <a
                        key={j + Math.random()}
                        href={brand.link}
                        className="w-full flex items-center"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={brand.logo} className="object-fit" alt="" />
                      </a>
                    );
                  })}
                </div>
                <div className="carousel p-4 space-x-4 lg:hidden">
                  {group.brands.map((brand, k) => {
                    return (
                      <div key={k} className="carousel-item bg-white">
                        {k === 0 ? (
                          <></>
                        ) : (
                          <a
                            href={brand.link}
                            rel="noreferrer"
                            target="_blank"
                            className="h-[100px] flex items-center"
                          >
                            <img
                              src={brand.logo}
                              className="h-full object-fit"
                              alt=""
                            />
                          </a>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default BrandsSection;

import React from "react";
import plane from "../assets/img/plane.webp";
import logo from "../assets/img/logo.webp";
import caretRightPattern from "../assets/img/caret-right-pattern.webp";
import { Link } from "react-router-dom";
function KnowUsSection() {
  return (
    <div
      id="nosotros"
      className="w-full flex items-center justify-center py-16 lg:my-12"
    >
      <div className="w-full flex items-center justify-center [@media(min-width:1024px)]:hidden">
        <div className="card w-96 bg-neutral shadow-xl image-full ">
          <figure>
            <img src={plane} alt="Shoes" />
          </figure>
          <div className="card-body bg-caretRightPattern">
            <h2 className="card-title">
              <img src={logo} className="w-1/2" alt="" />
            </h2>
            <p className="mb-4">
              Queremos ofrecerle una experiencia personalizada. Nos
              especializamos en transporte urgente hecho a medida. Nos complace
              ofrecer un servicio que garantice las expectativas de nuestros
              clientes. Nuestro joven y experimentado equipo estará entusiasmado
              de atenderles.
            </p>
            <div className="card-actions">
              <Link to="/register" className="btn btn-primary">
                REGISTRARME
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full max-w-7xl grid-cols-2 gap-40 hidden lg:grid">
        <img src={plane} alt="" className="h-full" />
        <div className="card w-full bg-neutral border-none rounded-[30px] p-4">
          <div className="card-body">
            <h2 className="card-title">
              <img src={logo} className="w-2/5" alt="" />
            </h2>
            <p className="text-lg">
              Queremos ofrecerle una experiencia personalizada. Nos
              especializamos en transporte urgente hecho a medida. Nos complace
              ofrecer un servicio que garantice las expectativas de nuestros
              clientes. Nuestro joven y experimentado equipo estará entusiasmado
              de atenderles.
            </p>
            <div className="card-actions mt-4">
              <Link to="/register" className="btn btn-primary px-10">
                REGISTRARME
              </Link>
            </div>
          </div>
        </div>
      </div>
      <img
        src={caretRightPattern}
        className="absolute left-0 h-[200px] hidden [@media(min-width:1024px)]:inline"
        alt=""
      />
    </div>
  );
}

export default KnowUsSection;

import React from "react";

type Props = {
  label?: string;
  onChange: (value: string) => void;
  error?: string;
  defaultValue?: string | number | readonly string[] | undefined;
};

function NumberInput({
  label = "",
  onChange,
  error = "",
  defaultValue,
}: Props) {
  const id = Math.random() + "";
  return (
    <div className="w-full flex flex-col">
      {!!label && (
        <label
          htmlFor={id}
          className={`text-sm lg:text-xl ml-2 mb-2 ${
            !!error ? "text-red-500" : ""
          }`}
        >
          {label}
        </label>
      )}
      <input
        id={id}
        type="number"
        defaultValue={defaultValue}
        className={`w-full h-min px-4 py-2 lg:p-4 border rounded-full text-xs lg:text-base  ${
          !!error
            ? "border-red-500 placeholder:text-red-500 focus:outline-none"
            : "border-secondary"
        }`}
        onChange={(e) => onChange(e.target.value)}
      />
      {!!error && (
        <span className="mt-2 text-red-500 text-xs lg:text-sm">{error}</span>
      )}
    </div>
  );
}

export default NumberInput;

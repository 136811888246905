import React, { useContext, useState } from "react";
import editIcon from "../assets/img/edit-icon.webp";
import { AuthContext } from "../providers/AuthProvider";
import usePaquetes from "../hooks/usePaquetes";
import LoadingBall from "../components/LoadingBall";
import { Link } from "react-router-dom";
import CasillasSection from "../components/CasillasSection";

type PackageState =
  | "casilla"
  | "transito"
  | "deposito"
  | "sucursal"
  | "retirado";

function Dashboard() {
  const { cliente } = useContext(AuthContext);
  const { paquetes, isLoading } = usePaquetes(cliente?.id || 0);
  const [state, setState] = useState<PackageState>("casilla");
  return (
    <div className="flex justify-center w-full min-h-screen bg-neutral">
      <div className="flex flex-col items-center w-full p-4 max-w-7xl">
        <div className="flex flex-col items-center w-full lg:flex-row lg:justify-between">
          <span className="text-xl lg:text-3xl">
            ¡Bienvenido/a!{" "}
            <span className="font-bold">
              {cliente?.nombre} / {cliente?.codigo}
            </span>
          </span>
          <div className="flex justify-center w-full lg:w-auto">
            <Link to={"/update-profile"} className="flex items-center my-8">
              <img src={editIcon} alt="" />
              <span className="ml-2 font-bold">Modificar mis datos</span>
            </Link>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center w-full lg:flex-row lg:items-end lg:justify-between">
          <span className="text-xl font-bold lg:text-2xl">Mis Paquetes</span>
          <div className="grid w-full grid-cols-2 gap-2 mt-4 lg:w-3/5 lg:grid-cols-5">
            <div
              onClick={() => setState("casilla")}
              className={`btn ${
                state === "casilla" ? "btn-primary" : "btn-secondary"
              }`}
            >
              EN CASILLA
            </div>
            <div
              onClick={() => setState("transito")}
              className={`btn ${
                state === "transito" ? "btn-primary" : "btn-secondary"
              }`}
            >
              EN TRANSITO
            </div>
            <div
              onClick={() => setState("deposito")}
              className={`btn ${
                state === "deposito" ? "btn-primary" : "btn-secondary"
              }`}
            >
              EN DEPÓSITO
            </div>
            <div
              onClick={() => setState("sucursal")}
              className={`btn ${
                state === "sucursal" ? "btn-primary" : "btn-secondary"
              }`}
            >
              EN SUCURSAL
            </div>
            <div
              onClick={() => setState("retirado")}
              className={`btn ${
                state === "retirado" ? "btn-primary" : "btn-secondary"
              }`}
            >
              RETIRADOS
            </div>
          </div>
        </div>

        <div className="w-full my-8">
          <div className="w-full bg-white card">
            <div className="card-body">
              <div className="overflow-x-auto">
                <table className="table">
                  {/* head */}
                  <thead>
                    <tr>
                      <th>#</th>
                      <th className="text-primary">TRACKING</th>
                      <th className="text-primary">DESCRIPCION</th>
                      <th className="text-primary">PESO (en Kg)</th>
                      <th className="text-primary">COSTO (en USD)</th>
                      <th className="text-primary">FECHA</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!isLoading &&
                      paquetes[state].map((p) => {
                        return (
                          <tr>
                            <th>{p.id}</th>
                            <td>{p.tracking}</td>
                            <td>{p.descripcion}</td>
                            <td>{p.peso}</td>
                            <td>{p.costo_calculado}</td>
                            <td>{p.created_at.slice(0, 10)}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                {!paquetes[state].length && !isLoading && (
                  <h2 className="my-20 text-3xl font-bold text-center text-primary">
                    No tienes paquetes
                  </h2>
                )}
                {isLoading && (
                  <div className="flex items-center justify-center w-full h-full">
                    <LoadingBall />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        
        <CasillasSection />


      </div>
    </div>
  );
}

export default Dashboard;

/**
 * {
                "id": 61464,
                "cliente_id": 126,
                "sucursal_id": 2,
                "tracking": "4203316692748903338851000009092594",
                "descripcion": "accesorios",
                "peso": "0.18",
                "costo_aduana": "",
                "comentarios": "",
                "medida": "1",
                "created_at": "2023-07-25 12:48:17",
                "updated_at": "2023-07-25 12:48:17",
                "tipo_carga_id": 1,
                "procedencia_id": 1,
                "tipo_paquete_id": 1,
                "tipo_carga_nombre": "Aérea",
                "procedencia_nombre": "Miami",
                "costo_calculado": "3,94",
                "peso_formateado": "0,180",
                "tieneFoto": true,
                "fotoLink": "http://api.pack2send.mambo.com.py/uploads/fotos/61464.jpg",
                "tipo_carga": {
                    "id": 1,
                    "nombre": "Aérea",
                    "descripcion": "En avión",
                    "created_at": "2018-03-16 17:33:57",
                    "updated_at": "2018-03-16 17:33:57"
                },
                "procedencia": {
                    "id": 1,
                    "nombre": "Miami"
                },
                "cliente": {
                    "id": 126,
                    "categoria_id": 2,
                    "codigo": "ABH8986",
                    "sucursal_id": 2,
                    "comentarios": "0000",
                    "email": "notificacionaerobox@gmail.com",
                    "pass": "$2y$10$HfUoSPErn74W.LFLVISyYuTBLAE9UQS8ceRZmQUl7grZ0fGC7/WsW",
                    "nombre": "AEROBOX LOGISTICA",
                    "cedula": "54654654",
                    "celular": "00000",
                    "created_at": "2018-07-09 18:50:21",
                    "estado": "activo",
                    "activation_code": "c2808e47c6ec6e03530bacb27c98ca7f",
                    "referencia": "0",
                    "info_id": 3,
                    "direccion": "",
                    "ciudad": "",
                    "notification_token": "cB1IEP2kjow:APA91bEp8ra1fhh4WlNWcPGe9xHDcPSWFaYQFWGgyYsVPFkU0U1Z_cpsVRHFNvtX1JPbGX6VnmVFJI55Ff9yIYUSd9Ug3f7nbuswA33mVw_APLv907bj5T1rAd2iW3ydOhDb8nYpjQxL",
                    "tipo_documento": null,
                    "fecha_nacimiento": null,
                    "sexo": null,
                    "ciudad_id": null
                }
            },
 * 
 * 
 */

import React, { useEffect, useState } from "react";

type Option = {
  label: string;
  value: string | number;
};

type Props = {
  label?: string;
  onChange: (value: string | number | null) => void;
  options: Option[];
  disabled?: boolean;
  error?: string;
  defaultSelected?: string | number;
};

function Select({
  label = "",
  onChange,
  options,
  disabled = false,
  error = "",
  defaultSelected,
}: Props) {
  const id = Math.random() + "";
  const [value, setValue] = useState<
    string | number | readonly string[] | undefined
  >(defaultSelected);

  return (
    <div
      className={`w-full flex flex-col ${
        disabled ? "pointer-events-none opacity-50" : ""
      }`}
    >
      <label
        htmlFor={id}
        className={`text-sm lg:text-xl ml-2 mb-2 ${
          !!error ? "text-red-500" : ""
        }`}
      >
        {label}
      </label>
      <select
        id={id}
        value={value}
        className={`w-full px-4 py-2 lg:p-4 border rounded-full text-xs lg:text-base ${
          !!error
            ? "border-red-500 placeholder:text-red-500 focus:outline-none"
            : "border-secondary"
        }`}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
      >
        <option value={undefined}></option>
        {options.map((option: Option, i) => {
          return (
            <option key={Math.random() + i + ""} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </select>
      {!!error && (
        <span className="mt-2 text-red-500 text-xs lg:text-sm">{error}</span>
      )}
    </div>
  );
}

export default Select;

import React from 'react';
import tarifa from '../assets/img/tarifas.png';
import tarifamb from '../assets/img/tarifasmb.png';

const ShippingRates = () => {
  const isSmallScreen = window.innerWidth < 768;
  return (
    <div id="tarifas" className="flex items-center justify-center w-full">
    <img
      src={isSmallScreen ? tarifamb : tarifa}
      alt="Shipping"
      className={`mt-4 ${isSmallScreen ? '' : 'px-40'}`}
    />
  </div>
  );
};

export default ShippingRates;
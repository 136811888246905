import React from 'react';
import casillas from '../assets/img/casillas.png';
import casillasmb from '../assets/img/casillamb.png';

const Casillas = () => {
  const isSmallScreen = window.innerWidth < 768;
  return (
    <div>
         <h2 className="mt-20 mb-8 text-4xl font-bold tracking-tighter text-center lg:text-5xl text-primary">
            Nuestras casillas
        </h2>
        
        
        <div className="flex items-center justify-center w-full py-12"> {/* Añadí flex y justify-center para centrar horizontalmente y items-center para centrar verticalmente */}
            <img src={isSmallScreen ? casillasmb : casillas} alt="Shipping" className="mt-4" />
        </div>
    </div>
  );
};

export default Casillas;
import axios from "axios";

export default axios.create({
  baseURL: "https://admin.pack2send.com.py/api",
  // baseURL: "http://localhost:8000/api",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: "Basic bWF4LnNvYXJlc0BtYW1iby5jb20ucHk6TWFtYm81OTU=",
  },
  responseType: "json",
});

import { useEffect, useState } from "react";
import logo from "../assets/img/logo.webp";
import EmailInput from "../components/Inputs/EmailInput";
import PasswordInput from "../components/Inputs/PasswordInput";
import { Link, useNavigate } from "react-router-dom";
import TextInput from "../components/Inputs/TextInput";
import Select from "../components/Inputs/Select";
import NumberInput from "../components/Inputs/NumberInput";
import useFormOptions from "../hooks/useFormOptions";
import LoadingBall from "../components/LoadingBall";
import useRegister from "../hooks/useRegister";
import ReCAPTCHA from "react-google-recaptcha-enterprise";

const INITIAL_FORM_VALUES = {
  tipoDocumento: "",
  pais: null,
  departamento: null,
  city_id: null,
  nombreCompleto: "",
  direccion: "",
  email: "",
  telefono: "",
  numeroDocumento: "",
  password: "",
  password_confirmation: "",
  "g-recaptcha-response": null,
};

function Register() {
  const {
    paises,
    departamentos,
    ciudades,
    tiposDocumentos,
    isLoading: isLoadingFormOptions,
  } = useFormOptions();

  const navigate = useNavigate();

  const { register, isLoading: isLoadingRegister, errors } = useRegister();

  const [form, setForm] = useState(INITIAL_FORM_VALUES);
  const [acceptTermsAndConditions, setAcceptTermsAndConditions] =
    useState(false);
  const updateForm = ({
    key,
    value,
  }: {
    key: string;
    value: string | number | null | undefined;
  }) => {
    setForm({ ...form, [key]: value });
  };

  const onChangeRecaptcha = (value: string | null) => {
    updateForm({ key: "g-recaptcha-response", value });
  };

  const registerUser = () => {
    register({
      params: form,
      onSuccess: () => {
        navigate("/success-register");
      },
    });
  };

  useEffect(() => {
    updateForm({ key: "city_id", value: null });
    updateForm({ key: "departamento", value: null });
  }, [form.pais]);

  return (
    <div className="flex items-center justify-center w-full h-full max-w-full min-h-screen p-4 overflow-x-hidden lg:px-0">
      <div className="w-full shadow-xl card lg:w-1/2 bg-base-100 lg:p-2">
        <div className="card-body">
          <div className="flex items-end justify-center w-full lg:justify-between">
            <Link to={"/"} className="cursor-pointer">
              <img src={logo} className="h-[73px]" alt="" />
            </Link>
            <span className="hidden text-xl font-bold lg:inline">
              CREÁ TU CUENTA
            </span>
          </div>
          <h2 className="justify-center mt-8 card-title lg:hidden">
            CREÁ TU CUENTA
          </h2>
          <div
            className={`w-full flex flex-col lg:grid lg:grid-cols-2 lg:gap-8 mt-2 ${
              isLoadingFormOptions || isLoadingRegister
                ? "pointer-events-none"
                : ""
            }`}
          >
            <div className="flex flex-col w-full">
              <div className="w-full mb-2">
                <TextInput
                  label="NOMBRES Y APELLIDOS"
                  onChange={(value) =>
                    updateForm({ key: "nombreCompleto", value })
                  }
                  error={errors.nombreCompleto[0]}
                />
              </div>
              <div className="w-full mb-2">
                <EmailInput
                  label="CORREO ELECTRÓNICO"
                  onChange={(value) => updateForm({ key: "email", value })}
                  error={errors.email[0]}
                />
              </div>
              <div className="w-full mb-2">
                <TextInput
                  label="PREFIJO + CELULAR"
                  onChange={(value) => updateForm({ key: "telefono", value })}
                  error={errors.telefono[0]}
                />
              </div>
              <div className="w-full mb-2">
                <Select
                  label="TIPO DE DOCUMENTO"
                  onChange={(value) =>
                    updateForm({ key: "tipoDocumento", value })
                  }
                  error={errors.tipoDocumento[0]}
                  options={tiposDocumentos.map((tc) => {
                    return {
                      label: tc.label,
                      value: tc.id,
                    };
                  })}
                />
              </div>
              <div className="w-full mb-2">
                <NumberInput
                  label="NÚMERO DE DOCUMENTO"
                  onChange={(value) =>
                    updateForm({ key: "numeroDocumento", value })
                  }
                  error={errors.numeroDocumento[0]}
                />
              </div>
            </div>
            <div className="flex flex-col w-full">
              <div className="w-full mb-2">
                <Select
                  label="PAÍS"
                  onChange={(value) => updateForm({ key: "pais", value })}
                  options={paises.map((pais) => {
                    return {
                      label: pais.nombre,
                      value: pais.id,
                    };
                  })}
                  error={errors.pais[0]}
                />
              </div>
              <div className="w-full mb-2">
                {!!form.pais ? (
                  <Select
                    label="DEPARTAMENTO"
                    onChange={(value) =>
                      updateForm({ key: "departamento", value })
                    }
                    options={departamentos
                      .filter((d) => d.pais_id + "" === form.pais + "")
                      .map((d) => {
                        return {
                          label: d.nombre,
                          value: d.id,
                        };
                      })}
                    error={errors.departamento[0]}
                  />
                ) : (
                  <Select
                    disabled={true}
                    label="DEPARTAMENTO"
                    onChange={() => {}}
                    options={[]}
                    error={errors.departamento[0]}
                  />
                )}
              </div>

              <div className="w-full mb-2">
                {form.departamento ? (
                  <Select
                    label="CIUDAD"
                    onChange={(value) => updateForm({ key: "city_id", value })}
                    error={errors.city_id[0]}
                    options={ciudades
                      .filter(
                        (c) => c.departamento_id + "" === form.departamento + ""
                      )
                      .map((ciudad) => {
                        return {
                          label: ciudad.nombre,
                          value: ciudad.id,
                        };
                      })}
                  />
                ) : (
                  <Select
                    disabled={true}
                    label="CIUDAD"
                    onChange={() => {}}
                    options={[]}
                    error={errors.city_id[0]}
                  />
                )}
              </div>

              <div className="w-full mb-2">
                <TextInput
                  label="DIRECCIÓN"
                  onChange={(value) =>
                    updateForm({ key: "direccion", value })
                  }
                  error={errors.direccion[0]}
                />
              </div>

              <div className="w-full mb-2">
                <PasswordInput
                  onChange={(value) => updateForm({ key: "password", value })}
                  label={"CONTRASEÑA"}
                  error={errors.password[0]}
                />
              </div>
              <div className="w-full mb-2">
                <PasswordInput
                  onChange={(value) =>
                    updateForm({ key: "password_confirmation", value })
                  }
                  label={"REPITA SU CONTRASEÑA"}
                  error={errors.password[0]}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center w-full">
            <div className="flex flex-col items-center w-full">
              <ReCAPTCHA
                sitekey="6Lfk7ZonAAAAAFvTfBIvv8fEKMaDJxvYXafSXqEh"
                onChange={onChangeRecaptcha}
              />
              <span className="mt-2 text-xs text-red-500 lg:text-sm">
                {errors["g-recaptcha-response"][0]}
              </span>
            </div>
            <div className="form-control">
              <label className="justify-start cursor-pointer label">
                <input
                  type="checkbox"
                  className="checkbox"
                  checked={acceptTermsAndConditions}
                  onChange={() =>
                    setAcceptTermsAndConditions(!acceptTermsAndConditions)
                  }
                />
                <span className="ml-2 label-text ">
                  HE LEÍDO Y ACEPTADO LOS TÉRMINOS Y CONDICIONES
                </span>
              </label>
            </div>
            {isLoadingFormOptions || isLoadingRegister ? (
              <div className="flex items-center justify-center w-full h-full">
                <LoadingBall />
              </div>
            ) : (
              <div className="flex flex-col items-center w-full lg:w-1/2">
                <div
                  className={`w-full btn btn-primary my-2 ${
                    acceptTermsAndConditions ? "" : "btn-disabled"
                  }`}
                  onClick={registerUser}
                >
                  CREAR CUENTA
                </div>
                <span className="text-lg">¿Ya tenés cuenta?</span>
                <Link to={"/login"} className="w-full my-2 btn btn-secondary">
                  INGRESAR
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;

import { useEffect, useState } from "react";
import api from "../services/api";

type Package = {
  id: number;
  tracking: string;
  descripcion: string;
  peso: string;
  costo_calculado: string;
  created_at: string;
};

type PackageGroup = {
  casilla: Package[];
  transito: Package[];
  deposito: Package[];
  sucursal: Package[];
  retirado: Package[];
};

const DEFAULT_VALUE: PackageGroup = {
  casilla: [],
  transito: [],
  deposito: [],
  sucursal: [],
  retirado: [],
};

const usePaquetes = (cliente_id: number) => {
  const [paquetes, setPaquetes] = useState<PackageGroup>(DEFAULT_VALUE);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    api
      .post("/get_paquetes", { cliente_id })
      .then(({ data }) => {
        const packages = data.object;
        setPaquetes({
          casilla: packages["Casilla"],
          transito: packages["Transito"],
          deposito: packages["Deposito"],
          sucursal: packages["Sucursal"],
          retirado: packages["Retirado"],
        });
      })
      .catch(console.log)
      .finally(() => setIsLoading(false));
  }, []);

  return { paquetes, isLoading };
};

export default usePaquetes;

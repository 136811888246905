import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/img/logo.webp";
function SuccessRegister() {
  return (
    <div className="w-full min-h-screen max-w-full overflow-x-hidden h-full flex justify-center items-center p-4 lg:px-0">
      <div className="card w-full lg:w-1/2 bg-base-100 shadow-xl lg:p-2">
        <div className="card-body">
          <div className="w-full flex flex-col items-center justify-center">
            <Link to={"/"} className="cursor-pointer">
              <img src={logo} className="h-[73px]" alt="" />
            </Link>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-24 h-24 stroke-green-600 mt-12 hover:animate-shake"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>

            <h2 className="font-bold text-3xl mb-12 text-green-600">
              REGISTRO EXITOSO
            </h2>
            <Link to={"/login"} className="w-full btn btn-primary mt-4">
              INGRESAR
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuccessRegister;

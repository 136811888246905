import React from "react";
import logo from "../assets/img/logo.webp";
import mamboLogo from "../assets/img/mambo-logo.webp";
function Footer() {
  return (
    <footer className="footer footer-center p-10 shadow-top">
      <div className="flex flex-col lg:flex-row items-center">
        <img src={logo} className="h-[50px] lg:h-[73px] mr-5" alt="" />
        <span>© Todos los derechos reservados.</span>
        <span>-</span>
        <span>Desarrollado por</span>
        <a href="https://www.mambo.com.py">
          <img src={mamboLogo} className="h-[40px] mr-5" alt="" />
        </a>
      </div>
    </footer>
  );
}

export default Footer;

import React, { useContext } from "react";
import logoMobile from "../assets/img/logo-mobile.webp";
import logo from "../assets/img/logo.webp";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../providers/AuthProvider";
const NavBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { onLogout, cliente } = useContext(AuthContext);
  console.log({ location });
  const goTo = (id: string) => {
    const top = document.getElementById(id)?.offsetTop;
    window.scrollTo({
      top,
      behavior: "smooth",
    });
  };

  const isDashboardPage = location.pathname === "/dashboard";
  const isUpdateProfilePage = location.pathname === "/update-profile";
  const isCasillasPage = location.pathname === "/casillas";

  const links = isDashboardPage || isUpdateProfilePage
    ? [
        {
          title: "Cerrar Sesión",
          onClick: () => {
            onLogout();
            navigate("/login");
          },
        },
      ]
    : [
        {
          title: "Nosotros",
          onClick: () => {
            if (isCasillasPage) {
              navigate("/");
              setTimeout(() => goTo("nosotros"), 100);
            } else {
              goTo("nosotros");
            }
          },
        },
        {
          title: "Servicios",
          onClick: () => {
            if (isCasillasPage) {
              navigate("/");
              setTimeout(() => goTo("servicios"), 100);
            } else {
              goTo("servicios");
            }
          },
        },
        {
          title: "Tarifas",
          onClick: () => {
            if (isCasillasPage) {
              navigate("/");
              setTimeout(() => goTo("tarifas"), 100);
            } else {
              goTo("tarifas");
            }
          },
        },
        {
          title: "Casillas",
          onClick: () => navigate("/casillas"),
        },
        {
          title: "Contacto",
          onClick: () => {
            if (isCasillasPage) {
              navigate("/");
              setTimeout(() => goTo("contacto"), 100);
            } else {
              goTo("contacto");
            }
          },
        },
        {
          title: "Mis Paquetes",
          onClick: () => navigate("/dashboard"),
        },
      ];
  return (
    <div className="w-full lg:h-[200px] flex justify-center bg-base-100 drop-shadow-lg">
      <div className="justify-between w-full navbar md:max-w-7xl lg:justify-start">
        <div className="navbar-start w-max">
          <div className="z-50 dropdown">
            <label tabIndex={0} className="btn btn-ghost lg:hidden">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h8m-8 6h16"
                />
              </svg>
            </label>
            <ul
              tabIndex={0}
              className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52"
            >
              {links.map((link, i) => {
                return (
                  <li key={i}>
                    <div
                      onClick={link.onClick}
                      className="font-bold cursor-pointer"
                    >
                      {link.title}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          <a href="/" className="h-[25px] md:hidden">
            <img className="object-contain h-full" src={logoMobile} alt="" />
          </a>
          <a href="/" className="hidden md:inline md:h-[30px] lg:h-[80px]">
            <img className="object-contain h-full" src={logo} alt="" />
          </a>
        </div>
        <div className="flex-1 hidden navbar-end lg:flex">
          <ul className="mr-2 menu menu-horizontal">
            {links.map((link, i) => {
              return (
                <li key={i}>
                  <span
                    onClick={link.onClick}
                    className="text-sm font-bold leading-none"
                  >
                    {link.title}
                  </span>
                </li>
              );
            })}
          </ul>
          {location.pathname != "/dashboard" && (
            <>
              <div className="flex items-center space-x-1 w-max">
                {!cliente && (
                  <a
                    href="/register"
                    className="btn btn-md btn-primary w-[180px]"
                  >
                    Registrarme
                  </a>
                )}

                <Link
                  to="/dashboard"
                  className="btn btn-md btn-secondary w-[180px]"
                >
                  Mi cuenta
                </Link>
              </div>
            </>
          )}
        </div>
        <div className="space-x-2 navbar-end lg:hidden">
          {location.pathname != "/dashboard" && (
            <>
              {!cliente && (
                <a href="/register" className="btn btn-sm btn-primary">
                  Registrarme
                </a>
              )}

              <Link to="/dashboard" className="btn btn-sm btn-secondary">
                Mi cuenta
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default NavBar;

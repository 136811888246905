import React from "react";
import ServicesSection from "../components/ServicesSection";
import KnowUsSection from "../components/KnowUsSection";
import SearchPackageSection from "../components/SearchPackageSection";
import BrandsSection from "../components/BrandsSection";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ContactSection from "../components/ContactSection";
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import ShippingRatesSection from "../components/ShippingRatesSection";



const Home = () => {
  // Create a client
  const queryClient = new QueryClient()
  return (
    <QueryClientProvider client={queryClient}>
      <div className="flex flex-col w-full">
        <SearchPackageSection />
        <KnowUsSection />
        <ServicesSection />
        <ShippingRatesSection />
        <div className="flex justify-center w-full py-16">
          <div className="w-full h-px border max-w-7xl"></div>
        </div>
        <BrandsSection />
        <ContactSection />
      </div>
    </QueryClientProvider>
  );
};

export default Home;

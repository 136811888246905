import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import WhatsappFloatButton from "../components/WhatsappFloatButton";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'

const queryClient = new QueryClient()

const Home = (props: {
  children?:
    | string
    | number
    | boolean
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
}) => {
  return (
    <QueryClientProvider client={queryClient}>
      <div className="w-screen min-h-screen overflow-hidden">
        <NavBar />
        <WhatsappFloatButton />
        {props.children}
        <Footer />
      </div>
    </QueryClientProvider>
  );
};

export default Home;

import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/css/tailwind.css";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./pages/Home";
import Login from "./pages/Login";
import AuthLayout from "./components/AuthLayout";
import Register from "./pages/Register";
import Dashboard from "./pages/Dashboard";
import SuccessRegister from "./pages/SuccessRegister";
import { AuthProvider } from "./providers/AuthProvider";
import ProtectedRoute from "./components/ProtectedRoute";
import SearchPackage from "./pages/SearchPackage";
import UpdateProfile from "./pages/UpdateProfile";
//import SucursalesSection from "./components/SucursalesSection";
import CasillasSection from "./components/CasillasSection";
import SuccessUpdateProfile from "./pages/SuccessUpdateProfile";
const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Layout>
        <Home />
      </Layout>
    ),
  },
  {
    path: "/casillas",
    element: (
      <Layout>
        <CasillasSection />
      </Layout>
    ),
  },
  {
    path: "/dashboard",
    element: (
      <ProtectedRoute>
        <Layout>
          <Dashboard />
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: "/update-profile",
    element: (
      <ProtectedRoute>
        <Layout>
          <UpdateProfile />
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: "/login",
    element: (
      <AuthLayout>
        <Login />
      </AuthLayout>
    ),
  },
  {
    path: "/register",
    element: (
      <AuthLayout>
        <Register />
      </AuthLayout>
    ),
  },
  {
    path: "/success-register",
    element: (
      <AuthLayout>
        <SuccessRegister />
      </AuthLayout>
    ),
  },
  {
    path: "/success-profile-update",
    element: (
      <AuthLayout>
        <SuccessUpdateProfile />
      </AuthLayout>
    ),
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

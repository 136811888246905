import React, { ReactNode, useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../providers/AuthProvider";

function AuthLayout(props: { children: ReactNode }) {
  const { cliente } = useContext(AuthContext);
  if (cliente) {
    return <Navigate to="/" replace />;
  }
  return (
    <div
      className="w-screen max-w-screen overflow-x-hidden min-h-screen bg-auth"
      style={{ overflowX: "hidden" }}
    >
      <div className="w-full h-full bg-black bg-opacity-70">
        {props.children}
      </div>
    </div>
  );
}

export default AuthLayout;

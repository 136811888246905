import React, { useState } from "react";
import caretDownRounded from "../assets/img/caret-down-rounded.webp";
import { Link } from "react-router-dom";
const SearchPackageSection = () => {
  const goDown = () =>
    window.scrollTo({
      top: document.getElementById("nosotros")?.offsetTop,
      behavior: "smooth",
    });
  const [query, setQuery] = useState("");
  return (
    <div className="flex justify-center w-full h-screen bg-center bg-cover bg-home py-36 lg:py-4">
      <div className="flex items-center justify-center w-full h-full max-w-7xl lg:justify-start">
        <div className="card bg-base-100 w-96 lg:w-1/2 h-max lg:p-4">
          <div className="card-body">
            <h2 className="text-2xl tracking-tighter card-title lg:text-4xl text-accent">
              SOMOS EXPERTOS EN TRANSPORTES SIN LIMITES.
            </h2>
            <span className="font-bold tracking-tight text-secondary">
              Rastreá tu envío (tracking)
            </span>
            <div className="flex items-center justify-between w-full overflow-hidden border rounded-full">
              <input
                type="text"
                placeholder="Tu código de rastreo"
                className={"input w-2/3 lg:flex-1 focus:outline-none"}
                value={query}
                onChange={(e) => setQuery(e.target.value.trim())}
              />
              <a
                href={`https://www.ordertracker.com/track/${query}`}
                target="_blank"
                className={`w-1/3 lg:w-max btn btn-primary ${
                  !query ? "pointer-events-none" : ""
                }`}
              >
                Buscar
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        onClick={goDown}
        className="absolute cursor-pointer z-90 -bottom-4 lg:-bottom-28 animate-bounce"
      >
        <img src={caretDownRounded} className="w-[60px] h-[60px]" alt="" />
      </div>
    </div>
  );
};

export default SearchPackageSection;

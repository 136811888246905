import { useContext, ReactNode, ReactElement } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../providers/AuthProvider";
import { JsxElement } from "typescript";

const ProtectedRoute = ({
  children,
}: {
  children: ReactElement;
}): ReactElement => {
  const { cliente } = useContext(AuthContext);
  if (!cliente) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default ProtectedRoute;

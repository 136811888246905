import { ReactNode, createContext, useState } from "react";
import { useNavigate } from "react-router-dom";

type Auth = {
  cliente: Cliente | null;
  onLogin: (cliente: Cliente) => void;
  onLogout: () => void;
  onUpdateProfile: (cliente: Cliente) => void;
};

export const AuthContext = createContext<Auth>({
  cliente: null,
  onLogin: () => {},
  onLogout: () => {},
  onUpdateProfile: (cliente: Cliente) => {},
});

export type Cliente = {
  id: number;
  nombre: string;
  codigo: string;
  email: string;
  celular: string;
  tipo_documento: string | null;
  cedula: string;
  ciudad_id: string | undefined | number;
  departamento_id: string | undefined | number;
  pais_id: string | undefined | number;
  direccion: string;
};

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const storedCliente = localStorage.getItem("cliente");
  const [cliente, setCliente] = useState<Cliente | null>(
    !!storedCliente ? JSON.parse(storedCliente) : null
  );

  const onLogin = (cliente: Cliente) => {
    setCliente(cliente);
    localStorage.setItem("cliente", JSON.stringify(cliente));
  };

  const onLogout = () => {
    setCliente(null);
    localStorage.removeItem("cliente");
  };

  const onUpdateProfile = (cliente: Cliente) => {
    localStorage.removeItem("cliente");
    localStorage.setItem("cliente", JSON.stringify(cliente));
  };

  const value = {
    cliente,
    onLogin,
    onLogout,
    onUpdateProfile,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

import React, { useContext, useState } from "react";
import logo from "../assets/img/logo.webp";
import EmailInput from "../components/Inputs/EmailInput";
import PasswordInput from "../components/Inputs/PasswordInput";
import { Link, useNavigate } from "react-router-dom";
import useLogin from "../hooks/useLogin";
import LoadingBall from "../components/LoadingBall";
import { AuthContext, Cliente } from "../providers/AuthProvider";
import ReCAPTCHA from "react-google-recaptcha-enterprise";
const INITIAL_FORM_VALUE = {
  email: "",
  password: "",
  "g-recaptcha-response": null,
};

function Login() {
  const navigate = useNavigate();
  const [form, setForm] = useState(INITIAL_FORM_VALUE);
  const setFormValue = ({
    key,
    value,
  }: {
    key: string;
    value: string | null;
  }) => {
    setForm({ ...form, [key]: value });
  };

  const { login, errors, isLoading } = useLogin();

  const { onLogin } = useContext(AuthContext);

  const attemptLogin = () => {
    login({
      params: form,
      onSuccess: (cliente: Cliente) => {
        onLogin(cliente);
        navigate("/dashboard");
      },
    });
  };

  const onChangeRecaptcha = (value: string | null) => {
    setFormValue({ key: "g-recaptcha-response", value });
  };
  return (
    <div className="flex items-center justify-center w-full h-screen px-4 lg:px-0">
      <div className="w-full shadow-xl card lg:w-1/4 bg-base-100 lg:p-2">
        <div className="card-body">
          {isLoading ? (
            <div className="flex justify-center w-full h-full">
              <LoadingBall />
            </div>
          ) : (
            <>
              <div className="flex justify-center w-full">
                <Link to={"/"} className="cursor-pointer">
                  <img src={logo} className="h-[73px]" alt="" />
                </Link>
              </div>
              <h2 className="justify-center mt-8 card-title">
                INGRESÁ A TU CUENTA
              </h2>
              <div className="w-full mb-2">
                <EmailInput
                  defaultValue={form.email}
                  onChange={(value) => setFormValue({ key: "email", value })}
                  label={"CORREO"}
                  error={errors.email[0]}
                />
              </div>
              <div className="w-full my-2">
                <PasswordInput
                  defaultValue={form.password}
                  onChange={(value) => setFormValue({ key: "password", value })}
                  label={"CONTRASEÑA"}
                  error={errors.password[0]}
                />
              </div>

              <div className="flex flex-col w-full">
                <ReCAPTCHA
                  sitekey="6Lfk7ZonAAAAAFvTfBIvv8fEKMaDJxvYXafSXqEh"
                  onChange={onChangeRecaptcha}
                />
                <span className="mt-2 text-xs text-red-500 lg:text-sm">
                  {errors["g-recaptcha-response"][0]}
                </span>
              </div>

              <div className="flex flex-col items-center w-full">
                <div
                  onClick={attemptLogin}
                  className="w-full my-2 btn btn-primary"
                >
                  INGRESAR
                </div>
                <span className="text-lg">¿Aún no tenés cuenta?</span>
                <Link
                  to={"/register"}
                  className="w-full my-2 btn btn-secondary"
                >
                  REGISTRARME
                </Link>
                <span className="text-lg">Restablecer constraseña</span>
                <Link
                  to={"https://admin.pack2send.com.py/restaurar_pass"}
                  className="w-full my-2 btn btn-info"
                >
                  Restablecer
                </Link>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Login;

import React from "react";

import courierServiceIcon from "../assets/img/courier-service-icon.svg";
import planeServiceIcon from "../assets/img/plane-service-icon.svg";
import shipServiceIcon from "../assets/img/ship-service-icon.svg";
import aduanaServiceIcon from "../assets/img/boxes.svg";
import callCenterServiceIcon from "../assets/img/callcenter-service-icon.svg";
import Service from "./Service";
import Slider from "react-slick";
function ServicesSection() {
  const services = [
    {
      icon: courierServiceIcon,
      title: "Courier",
      description:
        "Comprá de las mejores marcas de Europa y EE.UU. Nosotros nos encargamos de cuidar tus paquetes y te entregamos en la puerta de tu casa.",
    },
    {
      icon: planeServiceIcon,
      title: "Carga aérea",
      description:
        "Servicio especializado de manera rápida y urgente para pequeñas o grandes empresas que deseen importar o exportar.",
    },
    {
      icon: shipServiceIcon,
      title: "Carga marítima",
      description:
        "Operaciones de importación y exportación para personas físicas o jurídicas.",
    },
    {
      icon: aduanaServiceIcon,
      title: "Gestión aduanera",
      description:
        "Contamos con un equipo altamente capacitado para representar a nuestros clientes en todos los procesos tributarios y documentaciones que requieran sus mercaderías.",
    },
    {
      icon: callCenterServiceIcon,
      title: "Asesoramiento logístico",
      description:
        "Somos expertos en transporte sin límites, ofrecemos una experiencia única en el mercado hecho a medida según la necesidad del cliente y/o empresa.",
    },
  ];
  return (
    <div id="servicios" className="w-full py-12 ">
      <h2 className="text-4xl lg:text-5xl text-center font-bold text-secondary tracking-tighter">
        SERVICIOS
      </h2>
      <div className="w-full flex justify-center">
        <p className="lg:w-1/3 lg:px-12 text-lg text-center text-primary my-6">
          Te asesoramos, orientamos y facilitamos el mejor transporte aéreo,
          terrestre o marítimo desde cualquier parte del mundo.
        </p>
      </div>
      <div className="w-full flex justify-center">
        <div className="w-full max-w-7xl justify-center hidden lg:flex flex-col items-center">
          <div className="w-full flex justify-between">
            {services.slice(0, 3).map((service, i) => (
              <Service key={i} {...service} />
            ))}
          </div>
          <div className="w-full max-w-7xl flex justify-center px-32 mt-10">
            {services.slice(3, 6).map((service, i) => (
              <Service key={i + "" + Math.random()} {...service} />
            ))}
          </div>
        </div>
      </div>

      <div className="w-full lg:hidden">
        <Slider
          {...{
            dots: false,
            infinite: true,
            speed: 500,
            autoplay: true,
            autoplaySpeed: 4000,
            slidesToShow: 1,
            slidesToScroll: 1,
          }}
        >
          {services.map((service, i) => (
            <Service key={i} {...service} />
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default ServicesSection;

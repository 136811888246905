import { useState } from "react";
import api from "../services/api";
import axios from "axios";

type SendMessageParams = {
  nombre: string;
  telefono: string;
  email: string;
  asunto: string;
  mensaje: string;
  "g-recaptcha-response": string | null;
};

type SendMessageErrors = {
  nombre: string[];
  telefono: string[];
  email: string[];
  asunto: string[];
  mensaje: string[];
  server: string[];
  "g-recaptcha-response": string[];
};

const DEFAULT_ERROR_VALUES: SendMessageErrors = {
  nombre: [""],
  telefono: [""],
  email: [""],
  asunto: [""],
  mensaje: [""],
  server: [""],
  "g-recaptcha-response": [""],
};

const useSendMessage = () => {
  const [isSending, setIsSending] = useState(false);
  const [errors, setErrors] = useState<SendMessageErrors>(DEFAULT_ERROR_VALUES);

  const serverError = () => {
    setErrors({
      ...DEFAULT_ERROR_VALUES,
      server: ["No se pudo enviar el mensaje"],
    });
  };

  const sendMessage = ({
    params,
    onSuccess = () => {},
  }: {
    params: SendMessageParams;
    onSuccess?: () => void;
  }) => {
    setIsSending(true);
    return api
      .post("/enviar_mensaje", params)
      .then(() => {
        setErrors(DEFAULT_ERROR_VALUES);
        onSuccess();
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 422) {
            setErrors({ ...DEFAULT_ERROR_VALUES, ...error.response?.data });
          } else {
            serverError();
          }
        } else {
          serverError();
        }
      })
      .finally(() => setIsSending(false));
  };
  return { sendMessage, isSending, errors };
};
export default useSendMessage;

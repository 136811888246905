import React, { useEffect, useState } from "react";
import phoneIcon from "../assets/img/phone-icon.webp";
import envelopeIcon from "../assets/img/envelope-icon.webp";
import clockIcon from "../assets/img/clock-icon.webp";
import useSendMessage from "../hooks/useSendMessage";
import LoadingBall from "./LoadingBall";
import TextInput from "./Inputs/TextInput";
import EmailInput from "./Inputs/EmailInput";
import Textarea from "./Inputs/Textarea";
import ReCAPTCHA from "react-google-recaptcha-enterprise";

const INITIAL_FORM_VALUE = {
  nombre: "",
  telefono: "",
  email: "",
  asunto: "",
  mensaje: "",
  "g-recaptcha-response": null,
};

function ContactSection() {
  const { isSending, sendMessage, errors } = useSendMessage();

  const [form, setFormValue] = useState(INITIAL_FORM_VALUE);

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const updateFormField = ({
    key,
    value,
  }: {
    key: string;
    value: string | null;
  }) => setFormValue({ ...form, [key]: value });

  const onChangeRecaptcha = (value: string | null) => {
    updateFormField({ key: "g-recaptcha-response", value });
  };

  const sendContactMessage = () => {
    sendMessage({
      params: form,
      onSuccess: () => {
        setFormValue(INITIAL_FORM_VALUE);
        setShowSuccessMessage(true);
        setTimeout(() => setShowSuccessMessage(false), 4000);
      },
    });
  };

  return (
    <div id="contacto" className="flex justify-center w-full bg-neutral">
      {showSuccessMessage && (
        <div className="toast toast-bottom toast-left">
          <div className="alert alert-success text-secondary">
            <span>Consulta enviada exitosamente</span>
          </div>
        </div>
      )}

      <div className="flex flex-col items-center justify-center w-full py-20 max-w-7xl">
        <h2 className="mb-10 text-4xl font-bold tracking-tighter text-center lg:text-5xl text-secondary">
          CONTACTO
        </h2>
        <div className="flex flex-col items-center justify-center space-y-4 lg:w-full">
          <div className="flex w-full">
            <div className="w-full space-y-4 lg:w-1/3">
              <div className="flex items-center w-full">
                <img
                  src={phoneIcon}
                  className="w-[30px] h-[30px] object-contain"
                  alt=""
                />
                <div className="flex flex-col flex-1 pl-4">
                  <span className="font-bold text-secondary">Llamanos</span>
                  <a
                    href="tel:+595987111025"
                    className="text-sm no-underline hover:underline"
                  >
                    +595987111025
                  </a>
                </div>
              </div>
              <div className="flex items-center w-full">
                <img
                  src={envelopeIcon}
                  className="w-[30px] h-[30px] object-contain"
                  alt=""
                />
                <div className="flex flex-col flex-1 pl-4">
                  <span className="font-bold text-secondary">Email</span>
                  <a
                    href="mailto:paraguay@pack2send.com"
                    className="text-sm no-underline hover:underline"
                  >
                    paraguay@pack2send.com
                  </a>
                </div>
              </div>
              <div className="flex items-center w-full">
                <img
                  src={clockIcon}
                  className="w-[30px] h-[30px] object-contain"
                  alt=""
                />
                <div className="flex flex-col flex-1 pl-4">
                  <span className="font-bold text-secondary">
                    Horario de Atención
                  </span>
                  <span className="text-sm">
                    Lunes a Viernes: 08:00 a 18:00 HS
                  </span>
                  <span className="text-sm">Sábados: 08:00 a 13:00 HS</span>
                </div>
              </div>
              <div className="flex items-center mt-2">

              <iframe 
                height="450"
                title="map"
                style={{ border: 0 }}
                loading="lazy"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d862.6647977442038!2d-57.61699406380966!3d-25.311527016274834!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x945da90043afa8a7%3A0x12aa6f7a4be13705!2sPACK2SEND%20PY!5e0!3m2!1sen!2sus!4v1708960661895!5m2!1sen!2sus" 
              ></iframe>



              </div>
              <label
                htmlFor="contact_form_modal"
                className="w-full btn btn-primary lg:hidden"
              >
                CONSULTA
              </label>
              <input
                type="checkbox"
                id="contact_form_modal"
                className="modal-toggle"
              />
              <div className="modal lg:hidden">
                <div className="space-y-4 modal-box bg-neutral">
                  {isSending ? (
                    <div className="flex justify-center w-full h-full">
                      <LoadingBall />
                    </div>
                  ) : (
                    <>
                      <div className="flex flex-col w-full space-y-4">
                        <div className="w-full">
                          <TextInput
                            onChange={(value) =>
                              updateFormField({ key: "nombre", value })
                            }
                            placeholder="Nombre y apellido"
                            error={errors?.nombre[0]}
                            defaultValue={form.nombre}
                            value={form.nombre}
                          />
                        </div>
                        <div className="flex flex-col w-full space-y-4 lg:grid lg:grid-cols-2 lg:gap-4 lg:space-y-0">
                          <EmailInput
                            onChange={(value) =>
                              updateFormField({ key: "email", value })
                            }
                            placeholder="Correo"
                            defaultValue={form.email}
                            error={errors?.email[0]}
                          />
                          <TextInput
                            onChange={(value) =>
                              updateFormField({ key: "telefono", value })
                            }
                            placeholder="Teléfono"
                            defaultValue={form.telefono}
                            error={errors?.telefono[0]}
                          />
                        </div>
                        <div className="w-full">
                          <TextInput
                            defaultValue={form.asunto}
                            onChange={(value) =>
                              updateFormField({ key: "asunto", value })
                            }
                            placeholder="Asunto"
                            error={errors?.asunto[0]}
                          />
                        </div>
                        <div className="w-full">
                          <Textarea
                            placeholder="Mensaje"
                            defaultValue={form.mensaje}
                            onChange={(value) =>
                              updateFormField({ key: "mensaje", value })
                            }
                            error={errors?.mensaje[0]}
                          ></Textarea>
                        </div>
                        <div className="flex flex-col items-center w-full">
                          <ReCAPTCHA
                            sitekey="6Lfk7ZonAAAAAFvTfBIvv8fEKMaDJxvYXafSXqEh"
                            onChange={onChangeRecaptcha}
                          />
                          <span className="mt-2 text-xs text-red-500 lg:text-sm">
                            {errors["g-recaptcha-response"][0]}
                          </span>
                        </div>
                      </div>
                      <div className="flex flex-col space-x-0 space-y-1 modal-action">
                        <div
                          onClick={sendContactMessage}
                          className="w-full btn btn-primary"
                        >
                          Enviar consulta
                        </div>
                        <div className="w-full">
                          <label
                            htmlFor="contact_form_modal"
                            className="w-full btn btn-secondary"
                          >
                            Cancelar
                          </label>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="hidden w-2/3 lg:inline">
              {isSending ? (
                <div className="flex justify-center w-full h-full">
                  <LoadingBall />
                </div>
              ) : (
                <>
                  <div className="flex flex-col w-full space-y-4">
                    <div className="w-full">
                      <TextInput
                        onChange={(value) =>
                          updateFormField({ key: "nombre", value })
                        }
                        placeholder="Nombre y apellido"
                        error={errors?.nombre[0]}
                        defaultValue={form.nombre}
                        value={form.nombre}
                      />
                    </div>
                    <div className="flex flex-col w-full space-y-4 lg:grid lg:grid-cols-2 lg:gap-4 lg:space-y-0">
                      <EmailInput
                        onChange={(value) =>
                          updateFormField({ key: "email", value })
                        }
                        placeholder="Correo"
                        defaultValue={form.email}
                        error={errors?.email[0]}
                      />
                      <TextInput
                        onChange={(value) =>
                          updateFormField({ key: "telefono", value })
                        }
                        placeholder="Teléfono"
                        defaultValue={form.telefono}
                        error={errors?.telefono[0]}
                      />
                    </div>
                    <div className="w-full">
                      <TextInput
                        defaultValue={form.asunto}
                        onChange={(value) =>
                          updateFormField({ key: "asunto", value })
                        }
                        placeholder="Asunto"
                        error={errors?.asunto[0]}
                      />
                    </div>
                    <div className="w-full">
                      <Textarea
                        placeholder="Mensaje"
                        defaultValue={form.mensaje}
                        onChange={(value) =>
                          updateFormField({ key: "mensaje", value })
                        }
                        error={errors?.mensaje[0]}
                      ></Textarea>
                    </div>
                    <div className="flex flex-col items-center w-full">
                      <ReCAPTCHA
                        sitekey="6Lfk7ZonAAAAAFvTfBIvv8fEKMaDJxvYXafSXqEh"
                        onChange={onChangeRecaptcha}
                      />
                      <span className="mt-2 text-xs text-red-500 lg:text-sm">
                        {errors["g-recaptcha-response"][0]}
                      </span>
                    </div>
                  </div>
                  <div
                    onClick={sendContactMessage}
                    className="w-full mt-4 btn btn-primary"
                  >
                    ENVIAR CONSULTA
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactSection;

import { useContext, useEffect, useState } from "react";
import EmailInput from "../components/Inputs/EmailInput";
import PasswordInput from "../components/Inputs/PasswordInput";
import { Link, useNavigate } from "react-router-dom";
import TextInput from "../components/Inputs/TextInput";
import Select from "../components/Inputs/Select";
import NumberInput from "../components/Inputs/NumberInput";
import useFormOptions from "../hooks/useFormOptions";
import LoadingBall from "../components/LoadingBall";
import useUpdateProfile from "../hooks/useUpdateProfile";
import { AuthContext } from "../providers/AuthProvider";

function UpdateProfile() {
  const { onLogout, cliente } = useContext(AuthContext);
  const {
    paises,
    departamentos,
    ciudades,
    tiposDocumentos,
    isLoading: isLoadingFormOptions,
  } = useFormOptions();

  const navigate = useNavigate();

  const {
    updateClientProfile,
    isLoading: isLoadingRegister,
    errors,
  } = useUpdateProfile();

  const [form, setForm] = useState({
    tipoDocumento: cliente?.tipo_documento,
    pais: cliente?.pais_id,
    departamento: cliente?.departamento_id,
    city_id: cliente?.ciudad_id,
    nombreCompleto: cliente?.nombre,
    direccion: cliente?.direccion,
    email: cliente?.email,
    telefono: cliente?.celular,
    numeroDocumento: cliente?.cedula,
    password: "",
    password_confirmation: "",
  });
  const updateForm = ({
    key,
    value,
  }: {
    key: string;
    value: string | number | null | undefined;
  }) => {
    setForm({ ...form, [key]: value });
  };

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const updateProfile = () => {
    updateClientProfile({
      client_id: cliente?.id,
      params: form,
      onSuccess: () => {
        setShowSuccessMessage(true);
        setTimeout(() => setShowSuccessMessage(false), 4000);
        console.log(showSuccessMessage);
      },
    });
  };

  return (
    <div className="flex items-center justify-center w-full h-screen max-w-full p-4 overflow-x-hidden bg-neutral lg:px-0">
      {showSuccessMessage && (
        <div className="toast toast-top toast-end">
          <div className="alert alert-success text-secondary">
            <span>Perfil actualizado exitosamente</span>
          </div>
        </div>
      )}
      <div className="w-full mt-12 shadow-xl card lg:w-1/2 bg-base-100 lg:p-2 lg:mt-0">
        <div className="card-body">
          <div className="flex items-end justify-center w-full lg:justify-between">
            <span className="hidden text-xl font-bold lg:inline">
              Modificar datos
            </span>
          </div>
          <h2 className="justify-center mt-8 card-title lg:hidden">
            Modificar datos
          </h2>
          <div
            className={`w-full flex flex-col lg:grid lg:grid-cols-2 lg:gap-8 mt-2 ${
              isLoadingFormOptions || isLoadingRegister
                ? "pointer-events-none"
                : ""
            }`}
          >
            <div className="flex flex-col w-full">
              <div className="w-full mb-2">
                <TextInput
                  label="NOMBRES Y APELLIDOS"
                  defaultValue={cliente?.nombre}
                  onChange={(value) =>
                    updateForm({ key: "nombreCompleto", value })
                  }
                  error={errors.nombreCompleto[0]}
                />
              </div>
              <div className="w-full mb-2">
                <EmailInput
                  label="CORREO ELECTRÓNICO"
                  defaultValue={cliente?.email}
                  onChange={(value) => updateForm({ key: "email", value })}
                  error={errors.email[0]}
                />
              </div>
              <div className="w-full mb-2">
                <TextInput
                  label="PREFIJO + CELULAR"
                  defaultValue={cliente?.celular}
                  onChange={(value) => updateForm({ key: "telefono", value })}
                  error={errors.telefono[0]}
                />
              </div>
              <div className="w-full mb-2">
                <Select
                  label="TIPO DE DOCUMENTO"
                  onChange={(value) =>
                    updateForm({ key: "tipoDocumento", value })
                  }
                  defaultSelected={cliente?.tipo_documento || undefined}
                  error={errors.tipoDocumento[0]}
                  options={tiposDocumentos.map((tc) => {
                    return {
                      label: tc.label,
                      value: tc.id,
                    };
                  })}
                />
              </div>
              <div className="w-full mb-2">
                <NumberInput
                  label="NÚMERO DE DOCUMENTO"
                  defaultValue={cliente?.cedula}
                  onChange={(value) =>
                    updateForm({ key: "numeroDocumento", value })
                  }
                  error={errors.numeroDocumento[0]}
                />
              </div>
            </div>
            <div className="flex flex-col w-full">
              <div className="w-full mb-2">
                <Select
                  key={form.pais}
                  label="PAÍS"
                  onChange={(value) => updateForm({ key: "pais", value })}
                  defaultSelected={form.pais}
                  options={paises.map((pais) => {
                    return {
                      label: pais.nombre,
                      value: pais.id,
                    };
                  })}
                  error={errors.pais[0]}
                />
              </div>
              <div className="w-full mb-2">
                {!!form.pais ? (
                  <Select
                    key={form.departamento + "" + form.pais}
                    label="DEPARTAMENTO"
                    onChange={(value) =>
                      updateForm({ key: "departamento", value })
                    }
                    defaultSelected={form.departamento}
                    options={departamentos
                      .filter((d) => d.pais_id + "" === form.pais + "")
                      .map((d) => {
                        return {
                          label: d.nombre,
                          value: d.id,
                        };
                      })}
                    error={errors.departamento[0]}
                  />
                ) : (
                  <Select
                    disabled={true}
                    label="DEPARTAMENTO"
                    onChange={() => {}}
                    options={[]}
                    error={errors.departamento[0]}
                  />
                )}
              </div>

              <div className="w-full mb-2">
                {form.departamento ? (
                  <Select
                    key={form.departamento + "" + form.city_id}
                    label="CIUDAD"
                    onChange={(value) => updateForm({ key: "city_id", value })}
                    error={errors.city_id[0]}
                    defaultSelected={form.city_id}
                    options={ciudades
                      .filter(
                        (c) => c.departamento_id + "" === form.departamento + ""
                      )
                      .map((ciudad) => {
                        return {
                          label: ciudad.nombre,
                          value: ciudad.id,
                        };
                      })}
                  />
                ) : (
                  <Select
                    disabled={true}
                    label="CIUDAD"
                    onChange={() => {}}
                    options={[]}
                    error={errors.city_id[0]}
                  />
                )}
              </div>

              <div className="w-full mb-2">
                <TextInput
                  label="DIRECCIÓN"
                  defaultValue={cliente?.direccion}
                  onChange={(value) =>
                    updateForm({ key: "direccion", value })
                  }
                  error={errors.direccion[0]}
                />
              </div>

              <div className="w-full mb-2">
                <PasswordInput
                  onChange={(value) => updateForm({ key: "password", value })}
                  label={"CONTRASEÑA"}
                  error={errors.password[0]}
                />
              </div>
              <div className="w-full mb-2">
                <PasswordInput
                  onChange={(value) =>
                    updateForm({ key: "password_confirmation", value })
                  }
                  label={"REPITA SU CONTRASEÑA"}
                  error={errors.password[0]}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center w-full">
            {isLoadingFormOptions || isLoadingRegister ? (
              <div className="flex items-center justify-center w-full h-full">
                <LoadingBall />
              </div>
            ) : (
              <div className="flex flex-col items-center w-full lg:w-1/2">
                <div
                  className={`w-full btn btn-primary my-2`}
                  onClick={updateProfile}
                >
                  ACTUALIZAR
                </div>
                <Link
                  to={"/dashboard"}
                  className="w-full my-2 btn btn-secondary"
                >
                  CANCELAR
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateProfile;

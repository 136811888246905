import { useState } from "react";
import api from "../services/api";
import axios from "axios";

type RegisterParams = {
  tipoDocumento: string;
  pais: string | null;
  departamento: string | null;
  city_id: string | null;
  nombreCompleto: string;
  email: string;
  telefono: string;
  numeroDocumento: string;
  password: string;
  password_confirmation: string;
  "g-recaptcha-response": string | null;
};

type RegisterErrors = {
  tipoDocumento: string[];
  pais: string[];
  departamento: string[];
  city_id: string[];
  nombreCompleto: string[];
  direccion: string[];
  email: string[];
  telefono: string[];
  numeroDocumento: string[];
  password: string[];
  server: string[];
  "g-recaptcha-response": string[];
};

const DEFAULT_ERRORS: RegisterErrors = {
  tipoDocumento: [""],
  pais: [""],
  departamento: [""],
  city_id: [""],
  nombreCompleto: [""],
  direccion: [""],
  email: [""],
  telefono: [""],
  numeroDocumento: [""],
  password: [""],
  server: [""],
  "g-recaptcha-response": [""],
};

const useRegister = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<RegisterErrors>(DEFAULT_ERRORS);

  const serverError = () => {
    setErrors({
      ...DEFAULT_ERRORS,
      server: ["No se pudo realizar el registro"],
    });
  };
  const register = ({
    params,
    onSuccess,
  }: {
    params: RegisterParams;
    onSuccess: () => void;
  }) => {
    setIsLoading(true);
    return api
      .post("/auth/register", params)
      .then(() => {
        setErrors(DEFAULT_ERRORS);
        onSuccess();
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 422) {
            setErrors({ ...DEFAULT_ERRORS, ...error.response?.data });
          } else {
            serverError();
          }
        } else {
          serverError();
        }
      })
      .finally(() => setIsLoading(false));
  };

  return { register, isLoading, errors };
};

export default useRegister;
